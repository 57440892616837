.info-toggle {
  display: flex;
  position: absolute;
  top: 0px;
  left: 120px;
  align-items: center;
  margin-top: 20px;
  border: 0px;
}

@media (max-width: 576px) {
  .info-toggle {
    left: 100px;
  }
}

.info-toggle .toggle-button {
  background-color: #007bff;
  border: 1px solid #007bff;
  padding: 3px;
  width: 60px;
}

.info-toggle .toggle-button:hover {
  background-color: #0056b3;
  border: 1px solid #0056b3;
}
