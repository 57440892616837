.hwkeyboard-toggle {
  display: flex;
}

.hwkeyboard-text {
  margin-top: 3px;
  margin-right: 5px;
  font-size: 18px;
}
.hwkeyboard-icon {
  margin-top: -1px;
  margin-right: 5px;
  font-size: 18px;
}

.hwkeyboard-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.hwkeyboard-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
