.App,
.container {
  text-align: center;
  font-family: Arial, sans-serif;
}

.container {
  padding: 0px 20px;
}

.container.gamemode {
  height: 100dvh;
  display: flex;
  flex-direction: column;

  padding: 0px;
}

/* Light mode styles (default) */
body {
  background-color: #ffffff;
  color: #000000;
  max-width: 2000px;
  margin: auto;
}

/* Dark mode styles */

body.dark-mode {
  background-color: #272727;
  color: #ffffff;
}

div.row.flex-middle {
  display: flex;

  justify-content: center;
}

button.goodButton {
  width: 150px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 6px 0px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  display: flex;
  justify-content: center;
}

button.goodButton:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-2px);
}

button.goodButton.nextLevel {
  background-color: #13aa52;
  border: 1px solid #13aa52;
}

button.goodButton.restartLevel {
  background-color: #e96213;
  border: 1px solid #e96213;
}

button.goodButton .returnKey {
  color: buttontext;

  background-color: buttonface;

  border: 1px solid buttonborder;

  width: 20px;
  display: inline-block;
  margin-left: 8px;

  height: 26px;
  padding-top: 4px;
}

@media (max-width: 1200px) {
  button.goodButton .returnKey {
    display: none;
  }
}

button.goodButton .title {
  display: inline-block;
  padding: 7px 0px;
}

.levelHeader {
  margin-top: 20px;
  margin-bottom: 10px;
}

.loadingLevel {
  margin: 20px;
  color: white;
}

#CookiebotWidget {
  zoom: 50%;
}

#CookiebotWidget.CookiebotWidget-open {
  zoom: 100%;
}
