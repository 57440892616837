.explanation {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.explanation p {
  margin-top: 0;
  margin-bottom: 1em;
}
