.theme-toggle {
  display: flex;
  position: absolute;
  top: 0px;
  right: 20px;
  align-items: center;
  margin-top: 20px;
}

.slider-toggle {
  display: flex;
  position: absolute;
  align-items: center;
}

.topbar-toggle {
  left: 20px;
  top: 0px;
  margin-top: 20px;
}

@media (max-width: 576px) {
  .theme-toggle {
    right: 10px;
  }

  .topbar-toggle {
    /* left: 80px; */
    left: 10px;
    top: 0px;
    margin-top: 20px;
  }
}

.theme-icon {
  margin-top: -1px;
  margin-right: 5px;
  font-size: 18px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
