.gameboard {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 1200px) {
  .gameboard-container {
    margin-top: 10px;

    flex-grow: 1;
    overflow: scroll;
    overflow-x: hidden;

    background-color: #fffbf1;
    border: 1px solid #d3d3d3;
  }

  .dark-mode .gameboard-container {
    background-color: #222;
    border: 1px solid black;
  }

  .gameboard {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
}

.word-container {
  display: flex;
  align-items: center;
  margin: 0px;
}

.gameboard .whitespace {
  width: 20px;
}

.letter-container {
  height: 65px;
  margin-top: 3px;

  user-select: none;
  -webkit-user-select: none;
}

.letter-container button {
  color: #111;
  padding: 0px;
  font-size: 18px;

  width: 100%; /* Ensures the button fills the width of the container */
  height: 40px; /* Consistent button height */
}

.letter-container .regular {
  margin: 5px;
  width: 26px;
}

.letter-container .special {
  font-size: 24px;
  min-width: 12px;
}
.letter-container .special.upper {
  padding-top: 2px;
}
.letter-container .special.middle {
  padding-top: 10px;
}
.letter-container .special.lower {
  padding-top: 18px;
}

.letter-number {
  font-size: 12px;
  color: gray;
  margin-top: 5px;
}

.letter-number.easy {
  color: #ff91fb;
}

.selected {
  background-color: lightblue;
}
.selected.wrong,
.dark-mode .selected.wrong {
  background-color: lightcoral;
}

.dark-mode .selected {
  background-color: #87c3ff;
}

.dark-mode .letter-number {
  color: #888888;
}

.dark-mode .letter-number.easy {
  color: #ffe6fe;
}
