/* src/components/DifficultySlider.css */
.difficulty-slider {
  align-items: center;
  margin: 20px 0px;
}

.difficulty-slider label {
  margin-right: 0px;
}

.difficulty-slider input {
  margin: 0 10px;
  width: 150px;
  position: relative;
  top: 3px;
}

.difficultySetting {
  display: inline-block;
  width: 80px;
  text-align: left;
}

@media (max-width: 576px) {
  .difficulty-slider {
    margin-top: 5px;
  }
}
