.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  transition: opacity 0.5s ease;
}

.keyboard.faded {
  opacity: 0.5; /* Fades out the keyboard */
}

.keyboard-row {
  display: flex;
  justify-content: center;
  margin: 5px 0;
}

.keyboard button {
  margin: 3px;
  padding: 0px;
  width: 30px;
  font-size: 16px;
  cursor: pointer;
  height: 40px; /* Consistent button height */

  user-select: none;
  -webkit-user-select: none;
}

.keyboard button:disabled {
  background-color: 7c7c7c;
  color: lightgray;
  cursor: default;
}

.keyboard button.guessed {
  background-color: #ffb4fc;
}

.dark-mode .keyboard button:disabled {
  background-color: 7c7c7c;
  color: #5c5c5c;
}

.dark-mode .keyboard button.guessed {
  color: #333;
}

.keyboard button.arrowKey {
  background-color: #cc68ff;
  color: white;
  border: 1px #676767;
  border-style: solid;
}

@media (min-width: 576px) {
  .keyboard button {
    margin: 3px;
    width: 30px;
  }
  .arrowKey.left {
    margin-right: 12px;
  }
  .arrowKey.right {
    margin-left: 12px;
  }
}

@media (min-width: 768px) {
  .keyboard button {
    margin: 3px;
    width: 60px;
    font-size: 18px;
  }
  .keyboard button.arrowKey {
    width: 70px;
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .keyboard button {
    margin: 3px;
    width: 80px;
    font-size: 18px;
  }
  .keyboard button.arrowKey {
    width: 100px;
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .keyboard button {
    margin: 3px;
    width: 30px;
    font-size: 16px;
  }
  .keyboard button.arrowKey {
    width: 36px;
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  .keyboard {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 40px;
    margin-top: 0px;
  }
  .keyboard-container {
    height: 218px;
  }
  .arrowKey.left {
    margin-right: 12px;
  }
  .arrowKey.right {
    margin-left: 12px;
  }
}
